import { Controller } from "@hotwired/stimulus"
import ValidateFormController from "./validate_form_controller"

// Connects to data-controller="process-improvement"
export default class extends ValidateFormController {
  static targets = ["options", "template"]
  connect() {
    this.init_option_sortable();

    let edit_form = document.getElementById("process-improvement-edit-form");
    if (edit_form) {
      edit_form.addEventListener('change', this.checkChangedInputs);
    }

    if ($(".piq-tags").length) {
      this.setup_tagging();
    }
  }

  add_option(e) {
    e.preventDefault()
    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())
    this.optionsTarget.insertAdjacentHTML('beforeend', content);
    this.fix_table_widths();
  }

  confirm_delete(e) {
    if (!confirm(i18n_string("process_improvement.are_you_sure_you_want_to_delete_this_question","Are you sure you want to delete this question?  You will not be able to access the answers after you delete this question."))) {
      e.preventDefault();
    }
  }

  download_answers_click(e) {
    $("#piq-spinner-holder").fadeIn();
    $("#piq-spinner-holder").on('click',function(e){
      $("#piq-spinner-holder").fadeOut();
    });
    setTimeout(function(){
      $("#piq-spinner-holder").fadeOut();
    }, 2000);
    let btn = $(e.currentTarget);
    btn.blur();
  }

  email_template_copy(e){
    e.preventDefault();
    let iframe = document.getElementById('process-improvement-email-template-iframe');
    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      let template_html = iframe.contentWindow.document.body.innerHTML;
      let copy_success = copy_to_clipboard(template_html);
      $("#example_email_body_all_btn").blur();
      if(copy_success){
        $("#copy_msg_example_email_body_all").show().delay(3000).fadeOut('fast');
      }
    }
  }

  email_template_download(e){
    let iframe = document.getElementById('process-improvement-email-template-iframe');
    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      e.currentTarget.href='data:text/html;charset=UTF-8,'+encodeURIComponent(iframe.contentWindow.document.body.innerHTML);
    }
  }

  fix_table_widths() {
    $("#option_table_body td").each(function () {
      if (!$(this).data("fixed-width")) {
        $(this).css("width", $(this).width());
        $(this).data("fixed-width", 1);
      }
    });
  }

  give_feedback_step_one_process(event) {
    event.preventDefault();
    if (this.validate_give_feedback_form(undefined)){
      let have_textarea = false;
      $(".piq-question").each(function(){
        if($(this).prop("tagName").toLowerCase() == "textarea")
        {
          have_textarea = true;
        }
      });
      if(!have_textarea)
      {
        $("#process-improvement-feedback-form").submit();
        return;
      }
      clearJsAlerts();

      // Confirmation view
      if($(".piq-question").length) {
        $(".piq-question").each(function(){
          let $el = $(this);
          let question_id = $el.data("question");
          if($el.is("textarea")) {
            var tastr = ""+$el.val();
            tastr = tastr.replace("\n","<br>");
            $("#confirm-process-improvement-"+question_id).html(tastr);
          } else if($el.is("select")) {
            var option_text = [];
            $el.find("option:selected").each(function(){
              option_text.push($(this).text());
            });
            $("#confirm-process-improvement-"+question_id).html(option_text.join(", "));
          }
        });
      }
      if($(".piq-question-radio").length) {
        let radio_names = [];
        $(".piq-question-radio").each(function(){
          let myname = $(this).attr("name");
          if(radio_names.indexOf(myname) == -1) {
            radio_names.push(myname);
          }
        });
        for(let i=0;i<radio_names.length;i++) {
          let inputname = radio_names[i];
          inputname = inputname.replace("[","\\[");
          inputname = inputname.replace("]","\\]");

          let checked_radio = $('input[name='+ inputname +']:checked');
          let question_id = checked_radio.data("question");
          let the_text = checked_radio.parent().text();
          $("#confirm-process-improvement-"+question_id).html(the_text);

        }
      }

      $("#process-improvement-feedback-step1").hide();
      $("#process-improvement-feedback-step2").show();
      setTimeout(function(){
        $('html, body').animate({
          scrollTop: $("#confirm-submit-button-container").offset().top - $("#confirm-submit-button-container").height() + $(window).height()
        }, 100);
        setTimeout(function(){
          $('html, body').stop();
        },201);
      },100);

    }
  }

  give_feedback_step_two_back(event) {
    event.preventDefault();
    $("#process-improvement-feedback-step2").hide();
    $("#process-improvement-feedback-step1").show();
  }

  give_feedback_step_two_process(event) {
    event.preventDefault();
    $("#process-improvement-feedback-form").submit();
  }

  init_option_sortable() {
    if ($("#option_table_body").length) {
      this.fix_table_widths();
      $("#option_table_body").sortable({
        update: function (event, ui) {
          $('#option_table_body tr').each(function (idx) {
            var inputField = $(this).find(".option-order-field");
            $(inputField).val(idx + 1);
          });
        }
      });
    }
  }

  preview_sample_question(e) {
    e.preventDefault();
    let $thisObj = $(e.currentTarget);
    $thisObj.blur();
    let question_data = $thisObj.data("question");
    if (question_data && question_data.id) {
      $("#add_piq_question_modal_display_question").html(question_data.name);
      $("#add_piq_question_modal_hidden_id").val(question_data.id);
      $("#add_piq_question_modal_example_text").addClass("hidden");
      $("#add_piq_question_modal_example_radio").addClass("hidden");
      $("#piq-preview-add-question-button").addClass("hidden");
      if (!$thisObj.hasClass("preview-only")) {
        $("#piq-preview-add-question-button").removeClass("hidden");
      }
      if (question_data.display_control == "text") {
        $("#add_piq_question_modal_example_text").removeClass("hidden");
      } else if (question_data.display_control == "radio") {
        $("#add_piq_question_modal_example_radio").removeClass("hidden");
        if (question_data.options && question_data.options.length) {
          let html = "";
          for (let i=0; i < question_data.options.length; i++) {
            html += '<div class="checkbox"><label><input type="radio" name="temp_example" value="1" readonly="readonly"> '+question_data.options[i]+'</label></div>';
          }
          $("#add_piq_question_modal_example_radio").html(html);
        }
      }
      $("#add_piq_question_modal").modal("show");
    }
  }

  question_type_change(e) {
    let $thisObj = $(e.currentTarget);
    let newVal = $thisObj.val();
    if (newVal == "select" || newVal == "radio") {
      // Show options
      $("#option_div").removeClass("hidden");
    } else {
      // Hide options
      $("#option_div").addClass("hidden");
    }
    if (newVal == "select") {
      // Show "Allow selection of multiple answers"
      $("#allow-multiple-select-div").removeClass("hidden");
    } else {
      // Hide "Allow"...
      $("#allow-multiple-select-div").addClass("hidden");
      $("#question_multiple").prop("checked", false);
    }

    if (newVal == "radio") {
      // Show "Allow selection of multiple answers"
      $("#allow-range-div").removeClass("hidden");
    } else {
      $("#allow-range-div").addClass("hidden");
    }
  }

  setup_tagging(){
    $('.dropdown.tags-holder').off('show.bs.dropdown').on('show.bs.dropdown', function(){
      console.log("DOGS");
      // Reset Stuff
      $(this).find('.save-tag-button').hide();
      $(this).find('.new-tag-input-field').val("");

      // Hide tags already added to this answer
      var dropdownItem = $(this);
      var answer_id = $(this).find(".dropdown-toggle").data("answer");
      if(answer_id) {
        dropdownItem.find('.new-tag-list-items > a').each(function(){
          $(this).removeClass("hide-for-search").show();
        });
        $("#tags-"+answer_id).find(".unassign-tag").each(function () {
          var tag_id = $(this).data("tag_id");
          dropdownItem.find('.answer-tag[data-id="'+tag_id+'"]').addClass("hide-for-search").hide();
        });
      }

      if($(this).find('.new-tag-list-items > a').not(".hide-for-search").length < 8){
        $(this).find('.show-all-tags').hide();
        $(this).find(".new-tag-list-items").removeClass("all");
      } else {
        $(this).find('.show-all-tags').show();
        $(this).find(".new-tag-list-items").removeClass("all");
      }
    });

    $('.dropdown.tags-holder').off('click').on('click', function(e) {
      let targetElement = $(e.target);
      if (targetElement.hasClass("show-all-tags")) {
        targetElement.closest(".dropdown").find(".new-tag-list-items").addClass("all");
        targetElement.hide();
        e.stopPropagation();
      }
    });

    $(document).off('keyup', '.new-tag-input-field').on('keyup', '.new-tag-input-field', function(){
      var dropdownParent = $(this).closest(".dropdown");
      if($(this).val().length > 0){
        dropdownParent.find(".show-all-tags").hide();
      } else {
        dropdownParent.find(".show-all-tags").show();
      }

      var data = $(this).val().toLowerCase();
      dropdownParent.find('.new-tag-list-items > a').filter(function(){
        var item = $(this).text().toLowerCase().indexOf(data) > -1;
        if($(this).hasClass("hide-for-search"))
        {
          item = false;
        }
        $(this).toggle(item);
      });

      // Check if we have an exact match. If not show the save button
      var exists = false;
      dropdownParent.find('.new-tag-list-items > a:visible').each(function(i){
        if($(this).text().toLowerCase() == data){
          exists = true;
          console.log(data)
        }
      });

      if(!exists){
        dropdownParent.find('.save-tag-button').show();
      } else {
        dropdownParent.find('.save-tag-button').hide();
      }
    });
    $(document).off('click','.save-tag-button button').on('click','.save-tag-button button', function(e){
      e.preventDefault();
      let item = $(this);
      let answer_id = item.data("answer");
      let question_id = item.data("question");
      let customer_sub_account_id = item.data("customer_sub_account_id");
      let dropdownParent = item.closest(".dropdown");
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: '/process-improvement/'+question_id+'/create-tag',
        type: 'POST',
        data: {
          answer_id: answer_id,
          customer_sub_account_id: customer_sub_account_id,
          name: $("#new_tag_"+answer_id).val()
        },
        success: function(return_data) {
          if (return_data.tag_id) {
            // return_data. tag_id/name/answer_id
            let tag_html = ' <span class="badge bg-info mb-1 mt-1">' + return_data.name + ' <em class="fa fa-times unassign-tag" data-answer="' + return_data.answer_id + '" data-tag_id="' + return_data.tag_id + '" data-question="' + question_id + '"></em></span>';
            $('#tags-' + return_data.answer_id).append(tag_html);
            // Add tag to all dropdowns
            let html = '<a class="dropdown-item answer-tag" data-id="' + return_data.tag_id + '" data-question="' + question_id + '" data-tag="' + return_data.name + '" data-answer="%%answer_id%%">' + return_data.name + '</a>';
            $(".new-tag-list-items").each(function () {
              let myhtml = html.replace("%%answer_id%%", $(this).data("answer_id"));
              $(this).append(myhtml);
            });
          }
        }
      });
    });

    // Assign the tag from the dropdown to the specific answer
    $(document).off('click', '.answer-tag').on('click', '.answer-tag', function(e){
      e.preventDefault();

      let answer_id = $(this).data('answer');
      let tag_id = $(this).data('id');
      let question_id = $(this).data('question');
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: '/process-improvement/'+question_id+'/assign-tag',
        type: 'POST',
        data: {
          answer_id: answer_id,
          tag_id: tag_id
        },
        success: function(return_data) {
          if (return_data.tag_id) {
            // return_data. tag_id/name/answer_id
            let tag_html = ' <span class="badge bg-info mb-1 mt-1">' + return_data.name + ' <em class="fa fa-times unassign-tag" data-answer="' + return_data.answer_id + '" data-tag_id="' + return_data.tag_id + '" data-question="' + question_id + '"></em></span>';
            $('#tags-' + return_data.answer_id).append(tag_html);
          }
        }
      });

    });

    // Remove tag from the specific answer
    $(document).off('click', '.unassign-tag').on('click', '.unassign-tag', function(){
      let item = $(this);
      // <em class="fa fa-times unassign-tag" data-answer="<%= answer.id %>" data-tag_id="<%= tag.id %>" data-question="<%= @improvement_question.id %>"></em></span>

      let answer_id = item.data('answer');
      let tag_id = item.data('tag_id');
      let question_id = item.data('question');

      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: '/process-improvement/'+question_id+'/unassign-tag',
        type: 'POST',
        data: {
          answer_id: answer_id,
          tag_id: tag_id
        },
        success: function(return_data) {
          // return_data. status
          if (return_data && return_data.status == 'success') {
            item.parent().remove();
          }
        }
      });

    });

  }

  toggle_active(e){
    e.preventDefault();
    let $thisObj = $(e.currentTarget);
    let is_active = $thisObj.data("active") ? 1 : 0;
    let question_id = $thisObj.data("question_id");
    $thisObj.blur();

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: '/process-improvement/'+question_id+'/toggle-active',
      type: 'PATCH',
      data: {
        active: is_active,
        question_id: question_id
      },
      success: function(return_data) {
        if (return_data && return_data.question_id && return_data.active !== undefined) {
          let $status_elem = $(".toggle-active-question[data-question_id='"+return_data.question_id+"']");
          $status_elem.data("active",return_data.active);
          let active_text = i18n_string("process_improvement.active","Active");
          let inactive_text = i18n_string("process_improvement.inactive","Inactive");
          if (return_data.active == 1) {
            $status_elem.html('<span class="text-success"><em class="fas fa-star"></em> '+active_text+'</span>');
          } else {
            $status_elem.html('<span class="text-warning"><em class="fal fa-star"></em> '+inactive_text+'</span>');
          }
        }
        if(return_data && return_data.error)
        {
          alert(return_data.error);
        }
      }
    });
  }

  update_filter_click(e) {
    // // Reset action if we need to
    // if ($("#update_view_filtering_form").data("original-action")) {
    //   $("#update_view_filtering_form").attr("action", $("#update_view_filtering_form").data("original-action"));
    //   $("#update_view_filtering_form").data("original-action", undefined);
    // }
  }

  validate_give_feedback_form(event) {
    let error_message = [];
    if($(".piq-question").length)
    {
      $(".piq-question").each(function(){
        if($(this).val() == "" || $(this).val() == null)
        {
          let fieildsetObj = $(this).closest("fieldset");
          if(fieildsetObj.data("isrequired") == "yes") {
            let qtext = fieildsetObj.find("legend").text();
            error_message.push(i18n_string("process_improvement.please_select_an_answer_for","Please select an answer for") + ' &quot;' + qtext + '&quot;')
          }
        }
      });
    }
    if($(".piq-question-radio").length)
    {
      let radio_names = [];
      $(".piq-question-radio").each(function(){
        let myname = $(this).attr("name");
        if(radio_names.indexOf(myname) == -1)
        {
          radio_names.push(myname);
        }
      });
      for(let i=0;i<radio_names.length;i++)
      {
        let inputname = radio_names[i];
        inputname = inputname.replace("[","\\[");
        inputname = inputname.replace("]","\\]");
        if (!$('input[name='+ inputname +']:checked').length) {
          let fieildsetObj = $('input[name='+ inputname +']').closest("fieldset");
          if(fieildsetObj.data("isrequired") == "yes") {
            let qtext = fieildsetObj.find("legend").text();
            error_message.push(i18n_string("process_improvement.please_select_an_answer_for","Please select an answer for") + ' &quot;' + qtext + '&quot;');
          }
        }

      }
    }
    if(error_message.length > 0) {
      if (event) {
        event.preventDefault();
      }
      let errormsg = error_message.join('<br />');
      doJsAlert('<div class="alert alert-danger">'+errormsg+'</div>');
      return false;
    }
    return true;
  }

  validate_piq_form(event) {
    var container = 'message-container'
    if(document.getElementsByClassName('user_feedback_survey')){
      container = 'custom-message-container'
    }

    let passed_normal_validation = super.validateForm(event, container);
    if (passed_normal_validation) {
      let errormsg = "";
      let question_type = $("#question_display_control").val();
      if(question_type == "select" || question_type == "radio") {
        // option_name_new/option_name
        let opt_count = 0;
        $(".option-name-input").each(function () {
          if ($(this).val() != "") {
            opt_count++;
          }
        });
        if (opt_count <= 1) {
          errormsg += i18n_string("process_improvement.you_must_have_at_least_two_options","You must have at least two options") + "<br />";
        }
      }
      if (errormsg != "") {
        event.preventDefault();
        errormsg = errormsg.substring(0, errormsg.length - 6);
        doJsAlert('<div class="alert alert-danger">'+errormsg+'</div>', container);
        return false;
      }
    }
  }
}
